import { http } from '../../services/client';
import * as API from "../../services/api";
import { getDatabase, ref, onValue } from 'firebase/database';

export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_REJECTED = 'LOGIN_REJECTED'; 
export const LOGOUT_FULFILLED = 'LOGOUT_FULFILLED'; 
export const FINANCE_DATA_BACK = 'FINANCE_DATA_BACK';
const loginRequest = () => ({
  type: LOGIN_PENDING,
});
const loginFailure = (error) => ({
  type: LOGIN_REJECTED,
});

export const logoutSuccess = () => ({
  type: LOGOUT_FULFILLED,
  payload: { loggedIn: false },
});

export const loginSuccess = (userData, completeOnboarding) => {
  localStorage.setItem("loggedIn", true);
  localStorage.setItem("user", JSON.stringify(userData?.user));
  localStorage.setItem("tokenResponse",JSON.stringify(userData?.user?.stsTokenManager));
  return (
  {
  type: LOGIN_FULFILLED,
  payload: {
    user: userData?.user,
    token: userData?._tokenResponse,
    loggedIn: true,
    username: userData?.user?.displayName,
    type: userData?.providerId,
    completeOnboarding:completeOnboarding
  },
})
};

export const COMPANY_DATA_FULFILLED = 'COMPANY_DATA_FULFILLED';
export const COMPANY_DATA_PENDING = 'COMPANY_DATA_PENDING';
export const COMPANY_DATA_REJECTED = 'COMPANY_DATA_REJECTED';

const companyDataRequest = () => ({
  type: COMPANY_DATA_PENDING,
});
const companyDataFailure = (error) => ({
  type: COMPANY_DATA_REJECTED,
});

export const companyDataSuccess = (companyData) => ({
  type: COMPANY_DATA_FULFILLED,
  payload: {
    companyData: companyData,
 
  },
});


export const PROJECT_DATA_FULFILLED = 'PROJECT_DATA_FULFILLED';
export const FINANCE_DATA_FULFILLED = 'FINANCE_DATA_FULFILLED';

export const projectDataSuccess = (projectData) => ({
    type: PROJECT_DATA_FULFILLED,
    payload: {
        projectData: projectData,
   
    },
  });

  
  export  const finaceDataSuccess = (finaceData) => ({
    type: FINANCE_DATA_FULFILLED,
    payload: {
        finaceData: finaceData,
        completeOnboarding: true
    },
  });

  export const finaceDataBack = (data) => ({
    type: FINANCE_DATA_BACK,
    payload: {
        finaceData: data,
    },
  })
  

  export const TERM_AND_CONDITION_FULFILLED = 'TERM_AND_CONDITION_FULFILLED';
  

  export const termNConditionAccept = (tnc) => ({
    type: TERM_AND_CONDITION_FULFILLED,
    payload: {
      TnC: tnc,
   
    },
  });


  export const GET_STARTED_FULFILLED = 'GET_STARTED_FULFILLED';
  

  export const getStarted = (type) => ({
    type: GET_STARTED_FULFILLED,
    payload: {
      GetStarted: type,
    },
  });

  export const ON_BOARDING_FULFILLED = 'ON_BOARDING_FULFILLED';
  

  export const onboardingData = (data) => ({
    type: ON_BOARDING_FULFILLED,
    payload: {
      onboardData: data,
    },
  });


export const ONBOARD_DEVELOPER_PENDING = 'ONBOARD_DEVELOPER_PENDING';
export const ONBOARD_DEVELOPER_SUCCESS = 'ONBOARD_DEVELOPER_SUCCESS';
export const ONBOARD_DEVELOPER_FAILURE = 'ONBOARD_DEVELOPER_FAILURE';

const postOnboardDeveloperRequest = () => ({
  type: ONBOARD_DEVELOPER_PENDING,
});

const postOnboardDeveloperSuccess = () => ({
  type: ONBOARD_DEVELOPER_SUCCESS,
});

const postOnboardDeveloperFailure = (error) => ({
  type: ONBOARD_DEVELOPER_FAILURE,
  payload: error,
});


export const postOnboardDeveloper = (raw) => async(dispatch) =>{
  dispatch(postOnboardDeveloperRequest())
  try{
    const res = await API.onboardDeveloper(raw);
    dispatch(postOnboardDeveloperSuccess(res))
  }catch(err){
    dispatch(postOnboardDeveloperFailure(err))
  }
}




export const ADD_COMPANY_DETAILS_PENDING = 'ADD_COMPANY_DETAILS_PENDING';
export const ADD_COMPANY_DETAILS_SUCCESS = 'ADD_COMPANY_DETAILS_SUCCESS';
export const ADD_COMPANY_DETAILS_FAILURE = 'ADD_COMPANY_DETAILS_FAILURE';

const addCompanyDetailsRequest = () => ({
  type: ADD_COMPANY_DETAILS_PENDING,
});

const addCompanyDetailsSuccess = () => ({
  type: ADD_COMPANY_DETAILS_SUCCESS,
});

const addCompanyDetailsFailure = (error) => ({
  type: ADD_COMPANY_DETAILS_FAILURE,
  payload: error,
});


export const addCompanyDetails = (raw) => async(dispatch) =>{
  dispatch(addCompanyDetailsRequest())
  try{
    const res = await API.addCompanyDetails(raw);
    dispatch(addCompanyDetailsSuccess(res))
  }catch(err){
    dispatch(addCompanyDetailsFailure(err))
  }
}




export const FINANCING_REQUIREMENT_PENDING = 'FINANCING_REQUIREMENT_PENDING';
export const FINANCING_REQUIREMENT_SUCCESS = 'FINANCING_REQUIREMENT_SUCCESS';
export const FINANCING_REQUIREMENT_FAILURE = 'FINANCING_REQUIREMENT_FAILURE';

const financingRequirementRequest = () => ({
  type: FINANCING_REQUIREMENT_PENDING,
});

const financingRequirementSuccess = () => ({
  type: FINANCING_REQUIREMENT_SUCCESS,
});

const financingRequirementFailure = (error) => ({
  type: FINANCING_REQUIREMENT_FAILURE,
  payload: error,
});


export const financingRequirement = (raw) => async(dispatch) =>{
  dispatch(financingRequirementRequest())
  try{
    const res = await API.financingRequirement(raw);
    dispatch(financingRequirementSuccess(res))
  }catch(err){
    dispatch(financingRequirementFailure(err))
  }
}


export const VALIDATE_USER_PENDING = 'VALIDATE_USER_PENDING';
export const VALIDATE_USER_SUCCESS = 'VALIDATE_USER_SUCCESS';
export const VALIDATE_USER_FAILURE = 'VALIDATE_USER_FAILURE';

const validateUserRequest = () => ({
  type: VALIDATE_USER_PENDING,
});

const validateUserSuccess = (valiateUser) => ({
  type: VALIDATE_USER_SUCCESS,
  payload:{valiateUser: valiateUser}
});

const validateUserFailure = (error) => ({
  type: VALIDATE_USER_FAILURE,
  payload: error,
});


export const validateUser = () => async(dispatch) =>{
  dispatch(validateUserRequest())
  try{
    const res = await API.validateUser();
    dispatch(validateUserSuccess(res))
    return res;
  }catch(err){
    dispatch(validateUserFailure(err))
  }
}


export const validateFirstTimeUser = (token) => async(dispatch) =>{
  dispatch(validateUserRequest())
  try{
    const res = await API.validateFirstTimeUser(token);
    dispatch(validateUserSuccess(res))
    return res;
  }catch(err){
    dispatch(validateUserFailure(err))
  }
}