import { ReactElement, useEffect, useState } from "react";
import { Box, Avatar, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useSelector, useDispatch } from "react-redux";
import * as projectActions from "../../../store/project";
import { CircularProgress } from '@mui/material';
import pending from "../../../assets/pending.svg";
import todo from "../../../assets/todo.svg";
import next from "../../../assets/next.svg";
import done from "../../../assets/done.svg";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function UnfinishedOnboarding() {
  const dispatch = useDispatch();
  const [percnt, setPercent] = useState(30)
  const onBoarding = useSelector((state) => state.auth?.onboardData);
  const valiateUser = useSelector((state) => state.auth?.valiateUser);
  const project = useSelector((state) => state?.project);



  useEffect(() => {
    if (valiateUser?.state === 7) {
      setPercent(100)
    }
    else {
      if (valiateUser?.state > 1 && valiateUser?.state < 7) {
        setPercent(60)
      }
    }

  }, [valiateUser]);

  useEffect(() => {
      const callHighlight = async( ) =>{
        if (valiateUser?.state != 7) {
          setTimeout(async () => {
            await dispatch(projectActions?.highlightFinancingRequirement())
          }, 1000);
          await dispatch(projectActions?.highlightCompanyDetails())
     
          setTimeout(async () => {
            await dispatch(projectActions?.highlightCompanyDetailsNot());
            await dispatch(projectActions?.highlightFinancingRequirementNot());
          }, 4000);
        }
        else if(valiateUser?.state === 5){
          await dispatch(projectActions?.highlightCompanyDetails())
          setTimeout(async () => {
            await dispatch(projectActions?.highlightCompanyDetailsNot());
          }, 4000);
    
        }
        else if(valiateUser?.state === 3){
          await dispatch(projectActions?.highlightFinancingRequirement())
          setTimeout(async () => {
            await dispatch(projectActions?.highlightFinancingRequirementNot());
          }, 4000);
        }
    
      }
      callHighlight()


  }, [valiateUser]);

  const financingData = async () => {
    if (valiateUser?.state !== 5 && valiateUser?.state !== 7) {
      const authdata = await dispatch(projectActions.openCompanyBackdrop('finance_data'));
    }
  }

  const companyData = async () => {
    if (valiateUser?.state !== 3 && valiateUser?.state !== 7) {
      const authdata = await dispatch(projectActions.openCompanyBackdrop('upload_files'));
    }
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "flex-start",
          gap: 4,
        }}
      >
        <CssBaseline />
        <Box pt={3}>
          <Typography variant="h6">
            Complete the below steps to finish your onboarding
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <Box
            sx={{
              width: "421px",
              borderRadius: "16px",

              padding: "8px",
              opacity: "1"
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", flexDirection: "row", }}>
                <Box sx={{ padding: "5px 8px" }}>
                  <CircularProgressWithLabel size={60} thickness={4.5} value={percnt} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "0px 8px ",
                    gap: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#12190F",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Onboarding progress
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#9C9C9C",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Complete the remaining tasks to get good deals
                  </Typography>
                </Box>
              </Box>

            </Box>
          </Box>
          <Box
            sx={{
              width: "421px",
              cursor: "pointer",
              borderRadius: "16px",
              border: "1px solid #E5E5E5",
              padding: "8px",
              opacity: 1,
              boxShadow: project?.highlightCompanyDetails ? "0px 0px 15px 0px #008080": "none"
            }}
              onClick={companyData}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box>
                  <img src={todo} heigh="24px" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px 8px ",
                    gap: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#12190F",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Company Details
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#9C9C9C",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Let us know about the company details
                  </Typography>

                  <Box sx={{ display: "flex", gap: 0.5, width: "84px", backgroundColor: onBoarding?.url?.length > 0 ? '#F1F8EE' : '#FEF8EB', color: "#E1A632", borderRadius: "8px", padding: "4px 8px" }}>

                    <Typography
                      variant="h6"
                      sx={{
                        color: (valiateUser?.state === 3 || valiateUser?.state === 7) ? '#008080' : '#E1A632',
                        fontSize: "40px",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      {(valiateUser?.state === 3 || valiateUser?.state === 7) ? 'Complete' : 'Pending'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <img src={next} heigh="24px" />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "421px",
              cursor: "pointer",
              borderRadius: "16px",
              border: "1px solid #E5E5E5",
              padding: "8px",
              opacity: 1,
              boxShadow: project?.highlightFinancingRequirement ? "0px 0px 15px 0px #008080": "none"
            }}
            onClick={financingData}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box>
                  <img src={todo} heigh="24px" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px 8px ",
                    gap: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#12190F",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Financing requirements
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#9C9C9C",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    Let us know on what financing requirements needed.
                  </Typography>
                  <Box sx={{ display: "flex", gap: 0.5, width: "84px", backgroundColor: onBoarding?.finance ? '#F1F8EE' : '#FEF8EB', color: "#E1A632", borderRadius: "8px", padding: "4px 8px" }}>

                    <Typography
                      variant="h6"
                      sx={{
                        color: (valiateUser?.state === 5 || valiateUser?.state === 7) ? '#008080' : '#E1A632',
                        fontSize: "40px",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      {(valiateUser?.state === 5 || valiateUser?.state === 7) ? 'Complete' : 'Pending'}
                    </Typography>
                  </Box>

                </Box>
              </Box>
              <Box>
                <img src={next} heigh="24px" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default UnfinishedOnboarding;




// <Box
// sx={{
//   width: "421px",
//   height: "83px",
//   borderRadius: "16px",
//   border: "1px solid #E5E5E5",
//   padding: "8px",
//   opacity:"0.6"
// }}
// >
// <Box sx={{ display: "flex", justifyContent: "space-between" }}>
//   <Box sx={{ display: "flex", flexDirection: "row" }}>
//     <Box>
//       <img src={todo} heigh="24px" />
//     </Box>
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: "column",
//         padding: "0px 8px ",
//         gap: 2,
//       }}
//     >
//       <Typography
//         variant="h6"
//         sx={{
//           color: "#12190F",
//           fontSize: "16px",
//           fontWeight: "700",
//         }}
//       >
//         To do
//       </Typography>
//       <Typography
//         variant="h6"
//         sx={{
//           color: "#9C9C9C",
//           fontSize: "14px",
//           fontWeight: "500",
//         }}
//       >
//         Project overview and financing requirements
//       </Typography>
//     </Box>
//   </Box>
//   <Box>
//     <img src={next} heigh="24px" />
//   </Box>
// </Box>
// </Box>