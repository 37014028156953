import React, { useRef, useState } from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { set, ref as databaseRef, getDatabase, onValue, remove, update } from "firebase/database";
import axios from "axios";
import config from "../../../config/config";
import upload from "../../../assets/upload.svg";
import { useSelector, useDispatch } from "react-redux";
import * as projectActions from "../../../store/project";
import * as authActions from "../../../store/auth";
import doc from "../../../assets/doc.svg";
import delt from "../../../assets/delete.svg";

function UploadCompanyFiles(props) {

  const dispatch = useDispatch();
  const [fileName, setFileName] = useState("");
  const [summary, setSummary] = useState("");
  const [url, setUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const state = useSelector((state) => state);
  const user = useSelector((state) => state?.auth?.user);
  const project = useSelector((state) => state.project);
  const onBoarding = useSelector((state) => state.auth?.onboardData);
  const token = useSelector((state) => state?.auth?.token);
  const handleFile = (file) => {
    setFileName(file.name);
  };
  const fileInputRef = useRef(null);

  const handleSummaryChange = (event) => {
    setSummary(event.target.value);
  };

  const handleFileInputChange = (event) => {
    event.stopPropagation();

    // const file = event.target.files[0];
    setFileName(event.target.files[0].name);
    const files = Array.from(event.target.files);
    setSelectedFile(files);
    console.log('files', event.target)

    const formData = new FormData();
    // formData.append('files', event.target.files);
    formData.append('name', 'project-test');
    files.forEach(file => {
      return formData.append('files', file)
    })

    axios.post('https://file-upload-backend-vqv7qc6r6q-as.a.run.app/upload/company', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Token': token?.idToken,
      }
    })
      .then(response => {
        console.log(response);
        if (response?.data?.msg?.files) {
          setUrl(response?.data?.msg?.files)
        }
      })
      .catch(error => {
        console.error(error);
      });


    // files.forEach(file => uploadFiles(file));
    // uploadFiles(file);

    // Here you can add logic to handle the selected file
    // For example, you can upload it to a server.
  };

  const handleIconClick = (e) => {
    console.log("handleIconClick");
    e.stopPropagation();
    fileInputRef.current.click();
  };

  const uploadFiles = async (file) => {

    const formData = new FormData();
    formData.append('key1', 'value1');
    formData.append('key2', 'value2');

    axios.post('https://api.example.com/endpoint', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.error(error);
      });

    // const regex = /[.#$\[\]@]/g;
    // let path = "default";

    // const userPath = state?.auth?.user?.email?.replace(regex, "-");
    // if (onBoarding?.company?.CompanyName?.length > 0) {
    //   path = onBoarding?.company?.CompanyName.replace(/ /g, "-");
    // }
    // const storage = getStorage();
    // const storageRef = ref(storage, `company/${userPath}/${path}/${file.name}`);
    // uploadBytes(storageRef, file)
    //   .then((snapshot) => {
    //     return getDownloadURL(snapshot.ref);
    //   })
    //   .then((downloadUrl) => {
    //     // uploadComplete(downloadUrl);
    //     const newUrl = { fileName: file?.name, url: downloadUrl };
    //     setUrl((prevUrls) => {
    //       if (prevUrls) {
    //         return [...prevUrls, newUrl]
    //       } else {
    //         return [newUrl]
    //       }
    //     }
    //     );
    //     // props?.setUrl(downloadUrl);
    //   });


    // const uploadUrl = `${API_HOST}/upload`; // Replace with your actual upload endpoint

    // // Prepare the data to be sent with the file
    // const data = {
    //   userPath,
    //   projectPath: path,
    // };

    // // Use sendFormData to upload the file
    // const result = await sendFormData(uploadUrl, data, file);

  };

  const uploadComplete = async (url) => {
    const project = await dispatch(projectActions.fileUploadSucces(url));
  };
  async function fetchData() {
    const db = getDatabase();
    const regex = /[.#$\[\]@]/g;
    const userEmail = user?.user?.email;
    const path = user?.user?.email?.replace(regex, "-");
    const userRef = ref(db, path);
    const [data, onboarding] = await new Promise((resolve, reject) => {
      // onValue(userRef, (snapshot) => {
      //   const data = snapshot.val();
      //   if (data && data.onboarding === true) {
      //     resolve([data?.data, true]);
      //   } else {
      //     resolve([data, false])
      //   }
      // }, (error) => {
      //   reject(error);
      // });
    });

    if (onboarding) {
      const company = await dispatch(authActions.onboardingData(JSON.parse(data)));
    }
  }
  const submitData = async () => {

    if (summary?.length > 0 || url?.length > 0) {
      const axiosConfig = {
        headers: {
          Authorization: `Bearer ` + config.token,
          "Content-Type": "application/json", // Adjust content type according to your API requirements
        },
      };
      const data = {
        "fields": {
          "Url": JSON.stringify(url),
          "AboutProject": summary
        },
      }
      const raw = {
        "fields": {
          "Url": "[{\"fileName\":\"Refy IC Pitch Deck.pdf\",\"url\":\"https://firebasestorage.googleapis.com/v0/b/rosy-ratio-419722.appspot.com/o/company%2Fpraveensonare007-gmail-com%2Fsdsa%2FRefy%20IC%20Pitch%20Deck.pdf?alt=media&token=9347edaf-d316-46ad-8ba6-5ce296d17afb\"}]",
          "AboutProject": "Upload your document to analyse\nYou can upload any existing presentation or"
        }
      };

      try {

        // const response = await axios.post(
        //   config.airtableUrlDetails,
        //   raw,
        //   axiosConfig
        // );
        // const db = getDatabase();
        // const regex = /[.#$\[\]@]/g;
        // const path = user?.email?.replace(regex, '-')
        // set(databaseRef(db, path), {
        //   data: JSON.stringify({
        //     ...onBoarding,
        //     url: JSON.stringify(url)
        //   }),
        //   onboarding: true
        // });

        // const company = await dispatch(authActions.onboardingData({...onBoarding,"Url": url }));
        const companyData = await dispatch(authActions.addCompanyDetails(data))
        const validateUsr = await dispatch(authActions.validateUser());
        const authdata = await dispatch(projectActions.closeCompanyBackdrop());
        // Optionally, you can reset the form fields after successful submission

        // Add any success handling logic here, such as showing a success message
      } catch (error) {
        console.error("Error submitting form:", error);
        // Add error handling logic here, such as showing an error message
      }
    }
  }

  const formHandler = (e) => {
    e.preventDefault();
    // const file = e.target[0].files[0];
    const files = Array.from(e.target[0].files);

    setSelectedFile(e.target[0].files)
    files.forEach(file => uploadFiles(file));
  };

  // const uploadFiles = (file) => {
  //   const storage = getStorage();
  //   debugger
  //   const storageRef = ref(storage, `project1/${file.name}`);
  //   //
  //   uploadBytes(storageRef, file).then((snapshot) => {
  //     console.log("Uploaded a blob or file!");
  //   });

  //   getDownloadURL(storageRef).then( (url) =>{
  //     console.log('url',url);
  //   })

  // };

  return (
    <Box sx={{ pt: 3 }}>
      <CssBaseline />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1, pt: 3 }}>
        <TextField
          label="Company Details"
          edit={false}
          value={summary}
          onChange={handleSummaryChange}
          sx={{ border: "0px" }}
          multiline
          minRows={5}
          maxRows={5}
        />
      </Box>
      <Box pt={2}>
        <Typography
          variant="h4"
          sx={{ color: "#12190F", fontSize: "18px", fontWeight: "700" }}
        >
          {" "}
          Upload your document to analyse
        </Typography>
        <Typography
          variant="h6"
          sx={{ color: "#ABABAB", fontSize: "14px", fontWeight: "500" }}
        >
          {" "}
          You can upload any existing presentation or documentation on the project in lieu of filling out the project description. Please note that only PowerPoint or Google Slide presentations, or PDFs made from them, will be accepted. PDFs made from scanned images would not qualify.
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          border: "dashed 2px #BCBCBC",
          borderSpacing: "8px",
          borderWidth: "1px",

          mt: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 4,
            cursor: "pointer",
          }}
        >
          <button
            onClick={handleIconClick}
            style={{
              cursor: "pointer",
              border: "0",
              backgroundColor: "#fff",
            }}
          >
            {/* You can replace the icon with any icon library or custom SVG */}
            <img src={upload} alt="Upload" height="32px" />
            <Typography
            variant="h6"
            sx={{
              fontSize: "16px",
              lineHeight: "32px",
              color: "#008080",
              fontWeight: "600",
            }}
          >
            Click to upload
          </Typography>
          </button>
          <input
            type="file"
            multiple
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileInputChange}
          />
 
          <Typography
            variant="h6"
            sx={{
              fontSize: "14px",
              lineHeight: "24px",
              color: "#808080",
            }}
          >
            Supported format : PDF, PPT, Word, Keynote
          </Typography>
        </Box>
      </Box>

      {selectedFile?.length > 0 && (
        <>
          {selectedFile?.map((file, index) => (
            <Box
              key={index}
              sx={{
                width: "100%",
                height: "68px",
                borderRadius: "8px",
                border: "1px solid #d8d8d8",
                padding: "8px",
                marginTop: "16px"
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center', padding: "8px 14px" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Box>
                    <img src={doc} heigh="32px" />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0px 16px ",
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#12190F",
                        fontSize: "16px",
                        fontWeight: "700",
                      }}
                    >
                      <a href={url} style={{ textDecoration: "none", color: "#12190F" }}>{file.name}</a>
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#9C9C9C",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    ></Typography>
                  </Box>
                </Box>
                <Box>
                  <img src={delt} heigh="32px" />
                </Box>
              </Box>
            </Box>
          ))}
        </>
      )}
      {/*
      <form onSubmit={handleFileInputChange}>
        <input type="file" className="input" />
        <button type="submit">Upload</button>
      </form> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          pt: 2,
          mb: 3,
          pb: 3,
          maxWidth: "500px",
        }}
      >
        <Button
          fullWidth
          variant="contained"
          sx={{
            textTransform: "capitalize",
            width: "70%",
            fontSize: "20px",
          }}
          onClick={() => submitData()}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}

export default UploadCompanyFiles;
