import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import HomePage from "../pages/home/HomePage";
import LoginPage from "../pages/login/LoginPage";
import SignUpPage from "../pages/signup/SignUpPage";
import LandingPage from "../pages/landingPage/LandingPage";
import Onboarding from "../pages/additionalDetails/AdditionalDetails";
import Letschat from "../pages/letschat/Letschat";
import ScheduleAnAppointment from "../pages/scheduleAnAppointment/ScheduleAnAppointment";
import SigninConfirm from "../pages/login/SigninConfirm";
import Dashboard from "../pages/dashboard/Dashboard";
import Projects from "../pages/projects/Projects";


export default function BasicRoutes() {
  const userdata = useSelector((state) => state.auth);
  return (
    <Routes>
   
      <Route path="/login" element={userdata.loggedIn ? <Navigate to= "/dashboard" /> : <LoginPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route path="/onboarding/:type" element={<Onboarding />} />
      <Route path="/lets-chat" element={<Letschat />} />
      <Route path="/schedule-an-appointment" element={<ScheduleAnAppointment />} />
      <Route path="/home" element={userdata.loggedIn ? <HomePage /> : <Navigate to="/" /> }  />
      <Route path="/dashboard" element = {userdata.loggedIn ? <Dashboard /> : <Navigate to="/login" />} />
      <Route path="/getstarted" element={<LandingPage />} />
      <Route path="/signin-confirm" element={ <SigninConfirm />} />
      <Route path="/projects" element={<Projects />} />
      <Route
          path="/"
          element={
            userdata.loggedIn ? <Navigate to= "/dashboard" /> : <LandingPage />
          }
        />
    </Routes>
  )
}
