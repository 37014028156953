import React, { useState } from "react";
import firebase from "../../firebase";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import Header from "../additionalDetails/components/Header";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Box, Button } from "@mui/material";
import underDevelopment from "../../assets/underDevelopment.svg";
import SideNavBar from "../dashboard/components/SideNavBar";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import * as authActions from "../../store/auth";
import logoutImg from "../../assets/logout.svg";

function Projects() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth);
  const logout = async (e) => {
    e.preventDefault();
    const company = await dispatch(
      authActions.logoutSuccess()
    );
    navigate('/login')
  };

  return (
    <>
      <SideNavBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "110px",
          width: "100%",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            paddingTop: "16px",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            flex: 1,
            width: "100%",
            paddingRight:"24px",
            gap: 1,
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: {md:"36px" , xs : "24px"},
              fontWeight: "700",
              letterSpacing: ".9px",
              lineHeight: "48.6px",
            }}
          >
            All Projects
          </Typography>
          <Box sx={{display:"flex", gap: 1}}>

          <Button
            variant="outlined"
            onClick={(e) => logout(e)}
            startIcon={<img src={logoutImg} />}
            sx={{ textTransform: "none", fontWeight: "500", fontSize: "14px", height:"48px" }}
          >
            Logout
          </Button>
            <Button
              variant="outlined"
              onClick={() => navigate("/lets-chat")}
              startIcon={<PhoneInTalkIcon />}
              sx={{
                textTransform: "none",
                fontWeight: "500",
                fontSize: "14px",
                height:"48px"
              }}
            >
              Contact support
            </Button>
          </Box>
        </Box>
  
      </Box>
    </>
  );
}
export default Projects;