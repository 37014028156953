import React, { ReactElement, useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  Button,
  Backdrop,
  IconButton,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import createproject from "../../../assets/createproject.svg";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import * as projectActions from "../../../store/project";
import UploadDocuments from "./UploadDocuments";
import ProjectBasicDetails from "./ProjectBasicDetails";
import ProjectSummary from "./ProjectSummary";
import OnBoardingHeader from "./OnBoardingHeader";
import ProjectAdditionalQuestions from "./ProjectAdditionalQuestions";
import ProjectConnectWithUs from "./ProjectConnectWithUs";
import ProjectFinalStep from "./ProjectFinalStep";
import UploadCompanyFiles from "./UploadCompanyFiles";
import QuestionsFinance from "./QuestionsFinance";

function OnBoardingDetails(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const project = useSelector((state) => state.project);

  const handleOpen = () => {
    setOpen(true);
  };
  const saveToSummary = async () => { };

  const nextToSummary = async () => {
    const authdata = await dispatch(projectActions.saveSummary({}));
  };
  return (
    <>
      <Box>
        <CssBaseline />
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
          open={project?.openCompanyFileBackdrop}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              height: "calc(100vh)",
              width: "60%",
              padding: "24px 64px",
              overflowY: "auto",
            }}
          >
            <OnBoardingHeader />
            {project?.uploadFiles && <UploadCompanyFiles />}
            {project?.viewFiles && "View Files"}
            {project?.financeData &&  <QuestionsFinance />}
          </Box>
        </Backdrop>
      </Box>
    </>
  );
}

export default OnBoardingDetails;
