export const AUTH_TOKEN = "/authorization/token/";
export const REVOKE_TOKEN = "/authorization/revoke_token/";
export const USER_DATA = "/authorization/user/";
export const SIGNUP = "/authorization/signup/";
export const USERS_DATA = "/authorization/users/";
export const GET_OTP = "/authorization/logininitiator/";
 

 


export const CONTACT_Us = '/appeglvdKSlu26wb6/tblTc6UUVvC4QyWxB';
export const REQUEST_DOCUMENTS = '/util/request-document';
export const POST_INTERESTED = '/util/iam-interested';
export const AM_I_INTERESTED = '/util/ami-interested';
export const INVITE_USER = '/util/invite-user/';
export const GET_TASKLIST = '/util/all-task/';
export const POST_TASKLIST = '/util/add-task/';
export const ADD_TO_TIMELINE = '/util/add-to-timeline/';
export const ALL_TIMELINE = '/util/all-timeline/';
export const ALL_DOCUMENTS = '/util/all-doc/';
export const INFORMATION_DEVELOPER ='/inv/investor-dashboard/';


export const ONBOARD_DEVELOPER = '/developer/onboard-developer';
export const ADD_COMPANY_DETAILS = '/developer/add-company-detail';
export const FINANCING_REQUIREMENT = '/developer/financing_requirement';
export const ADD_PROJECT = '/developer/add-project';

export const VALIDATE_USER = '/developer/validate-user';