import React, { ReactElement, useState } from "react";
import {
  Box,
  Avatar,
  Typography,
  Button,
  Backdrop,
  IconButton,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import Close from "../../../assets/close.svg";
import * as projectActions from "../../../store/project";

function OnBoardingHeader() {
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);
  const handleClose = async () => {
    const authdata = await dispatch(projectActions.closeCompanyBackdrop());
  };
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4" sx={{ color: "#12190F", fontWeight:"700", letterSpacing:"0.9px" }}>
          {project?.uploadFiles && "Upload Files"}
          {project?.viewFiles && "View Files"}
          {project?.financeData && "Financing Requirements"}
         
        </Typography>
        <IconButton aria-label="delete" size="small" onClick={handleClose}>
          <Box>
            <img src={Close} height={"32px"} />
          </Box>
        </IconButton>
      </Box>
      <Box pt={1}>
        <Typography
          variant="h6"
          sx={{ color: "#9C9C9C", fontSize: "14px", fontWeight: "500" }}
        >
 
        </Typography>
      </Box>
    </Box>
  );
}

export default OnBoardingHeader;
