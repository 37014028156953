import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import logo from "../../../assets/logo.png";
import Refy from "../../../assets/Refy.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as authActions from "../../../store/auth";

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  const user = useSelector((state) => state?.auth);
  const logout = async() => {
    const company = await dispatch(
      authActions.logoutSuccess()
    );
    navigate('/login')
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 16px",
          backgroundColor: "#ffffff",
          borderBottom: "solid 1px #E5E5E5",
        }}
      >
        <Box>
          <img src={Refy} height="48px" />
        </Box>
        <Box sx={{display:"flex", flexDirection:"row" , gap:2}}>
         
        {user?.loggedIn && location.pathname.includes('/home') && (
          <>  <Box sx={{display:"flex", alignItems:"center"}}><Typography>{user?.user?.displayName}</Typography></Box>
          <Button
            variant="outlined"
            onClick={() => logout()}
             
            sx={{ textTransform: "none", fontWeight: "500", fontSize: "14px" }}
          >
            Logout
          </Button>
          </>
        ) }
   
          <Button
            variant="outlined"
            onClick={() => navigate("/lets-chat")}
            startIcon={<PhoneInTalkIcon />}
            sx={{ textTransform: "none", fontWeight: "500", fontSize: "14px" }}
          >
            Contact support
          </Button>
          </Box>
      </Box>
    </>
  );
}

// "#E6F2F2"
