import {
  BACKDROP_OPEN_FULFILLED,
  BACKDROP_CLOSE_FULFILLED,
  FILE_UPLOAD_FULFILLED,
  SAVE_PROJECT_BASIC_DATA,
  SAVE_PROJECT_SUMMARY_DATA,
  SAVE_PROJECT_DATA,
  SAVE_PROJECT_GO_DASHBOARD,
  CONTACT_US_SUCCESS,
  BACK_PROJECT_BASIC,
  BACK_PROJECT_SUMMARY,
  BACK_PROJECT_QUESTION,
  BACK_PROJECT_FINAL,
  PROJECT_DATA_SAVE_DRAFT,
  INITIAL_PROJECT_DATA_SAVE,
  BACKDROP_COMPANY_FILE_OPEN_FULFILLED,
  BACKDROP_COMPANY_FILE_CLOSE_FULFILLED,
  ADD_PROJECT_PENDING,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAILURE,
  HIGHLIGHT_FINANCING_REQUIREMENT,
  HIGHLIGHT_COMPANY_DETAILS
} from "./actions";

const initialState = {
 snackbar: false,
 openBackdrop: false,
 url:"",
 basicData: true,
 summaryData: false,
 fileSentTollm: false,
 summaryFromLlm: false,
 additionalQuestion: false,
 contactUs: false,
 finalSteps: false,
 openCompanyFileBackdrop: false,
 uploadFiles: false,
 viewFiles: false,
 financeData: false,
 highlightFinancingRequirement: false,
 highlightCompanyDetails: false,
 createProjectpopup: false

};
export const projectreducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_FULFILLED": {
      return { ...state, ...action.payload };
    }
    case BACKDROP_OPEN_FULFILLED: {
      return { ...state, ...action.payload };
    }
    case   BACKDROP_CLOSE_FULFILLED: {
      return { ...state, ...action.payload };
    }
    case   FILE_UPLOAD_FULFILLED: {
      return { ...state, ...action.payload };
    }
    case SAVE_PROJECT_BASIC_DATA: {
      return {...state, ...action.payload};
    }
    case SAVE_PROJECT_SUMMARY_DATA:{
      return {...state, ...action.payload}
    }
    case SAVE_PROJECT_DATA:{
      return {...state, ...action.payload}
    }
    case SAVE_PROJECT_GO_DASHBOARD:{
      return { ...state, ...action.payload}
    }
    case CONTACT_US_SUCCESS:{
      return {...state, ...action.payload}
    }
    case BACK_PROJECT_BASIC:{
      return{...state, ...action.payload}
    }
    case BACK_PROJECT_SUMMARY:{
      return{...state, ...action.payload}
    }
    case BACK_PROJECT_QUESTION:{
      return{...state, ...action.payload}
    }
    case BACK_PROJECT_FINAL:{
      return{...state, ...action.payload}
    }
    case PROJECT_DATA_SAVE_DRAFT:{
      return{...state, ...action.payload}
    }
    case INITIAL_PROJECT_DATA_SAVE:{
      return{...state, ...action.payload}
    }
    case BACKDROP_COMPANY_FILE_OPEN_FULFILLED:{
      return{...state, ...action.payload}
    }
    case BACKDROP_COMPANY_FILE_CLOSE_FULFILLED:{
      return{...state, ...action.payload}
    }
    case   ADD_PROJECT_PENDING: {
      return{...state, ...action.payload}
    }
    case ADD_PROJECT_SUCCESS: {
      return{...state, ...action.payload}
    }
    case ADD_PROJECT_FAILURE:{
      return{...state, ...action.payload}
    }
    case HIGHLIGHT_FINANCING_REQUIREMENT:{
      return {...state, ...action.payload}
    }
    case HIGHLIGHT_COMPANY_DETAILS:{
      return {...state, ...action.payload}
    }
    default:
      return state;
  }
};
