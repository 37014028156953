import * as API from "../../services/api";
export const LOGIN_FULFILLED = "LOGIN_FULFILLED";
export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_REJECTED = "LOGIN_REJECTED";
export const LOGOUT_FULFILLED = "LOGOUT_FULFILLED";
export const BACKDROP_OPEN_FULFILLED = "BACKDROP_OPEN_FULFILLED";
export const BACKDROP_CLOSE_FULFILLED = "BACKDROP_CLOSE_FULFILLED";
export const FILE_UPLOAD_FULFILLED = "FILE_UPLOAD_FULFILLED";
export const SAVE_PROJECT_BASIC_DATA = "SAVE_PROJECT_BASIC_DATA";
export const SAVE_PROJECT_SUMMARY_DATA = "SAVE_PROJECT_SUMMARY_DATA";
export const SAVE_PROJECT_DATA = "SAVE_PROJECT_DATA";
export const SAVE_PROJECT_GO_DASHBOARD = "SAVE_PROJECT_GO_DASHBOARD";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";
export const BACK_PROJECT_BASIC = "BACK_PROJECT_BASIC";
export const BACK_PROJECT_SUMMARY = "BACK_PROJECT_SUMMARY";
export const BACK_PROJECT_QUESTION = "BACK_PROJECT_QUESTION";
export const BACK_PROJECT_FINAL = "BACK_PROJECT_FINAL";
export const PROJECT_DATA_SAVE = "PROJECT_DATA_SAVE";
export const PROJECT_DATA_SAVE_DRAFT = "PROJECT_DATA_SAVE_DRAFT";
export const INITIAL_PROJECT_DATA_SAVE = "INITIAL_PROJECT_DATA_SAVE";
export const BACKDROP_COMPANY_FILE_OPEN_FULFILLED = "BACKDROP_COMPANY_FILE_OPEN_FULFILLED";
export const BACKDROP_COMPANY_FILE_CLOSE_FULFILLED = "BACKDROP_COMPANY_FILE_CLOSE_FULFILLED";
export const HIGHLIGHT_COMPANY_DETAILS = "HIGHLIGHT_COMPANY_DETAILS";
export const HIGHLIGHT_FINANCING_REQUIREMENT = "HIGHLIGHT_FINANCING_REQUIREMENT";

const loginRequest = () => ({
  type: LOGIN_PENDING,
});
const loginFailure = (error) => ({
  type: LOGIN_REJECTED,
});

export const logoutSuccess = () => ({
  type: LOGOUT_FULFILLED,
  payload: { loggedIn: false },
});

export const openCompanyBackdrop = (type) =>{
if(type === 'upload_files'){
  return   ({
    type: BACKDROP_COMPANY_FILE_OPEN_FULFILLED,
    payload: { openCompanyFileBackdrop: true, uploadFiles: true },
  })
}else if(type === 'view_files'){
  return   ({
    type: BACKDROP_COMPANY_FILE_OPEN_FULFILLED,
    payload: { openCompanyFileBackdrop: true, viewFiles: true },
  })
}else if(type === 'finance_data'){
  return   ({
    type: BACKDROP_COMPANY_FILE_OPEN_FULFILLED,
    payload: { openCompanyFileBackdrop: true, financeData: true },
  })
}
}
  

export const closeCompanyBackdrop = () =>({
  type: BACKDROP_COMPANY_FILE_CLOSE_FULFILLED,
  payload: { openCompanyFileBackdrop: false,  uploadFiles: false,
    viewFiles: false,
    financeData: false },
})
export const openBackdrop = () => ({
  type: BACKDROP_OPEN_FULFILLED,
  payload: { openBackdrop: true },
});

export const closeBackdrop = () => ({
  type: BACKDROP_CLOSE_FULFILLED,
  payload: {
    openBackdrop: false,
    BasicData: {},
    fileSentTollm: false,
    basicData: true,
    contactUs: false,
    finalSteps: false,
    summaryData: false,
    additionalQuestion: false,
  },
});

export const fileUploadSucces = (url) => ({
  type: FILE_UPLOAD_FULFILLED,
  payload: { url: url, fileSentTollm: true },
});

export const projectBasicDataSuccess = (data) => ({
  type: SAVE_PROJECT_BASIC_DATA,
  payload: {
    ...data,
    basicData: false,
    summaryData: true,
    summaryFromLlm: false,
  },
});

export const projectBasicData = (data) => ({
  type: SAVE_PROJECT_BASIC_DATA,
  payload: {
    ...data,
  },
});

export const saveSummary = (data) => ({
  type: SAVE_PROJECT_SUMMARY_DATA,
  payload: {
    ...data,
    summaryFromLlm: false,
    fileSentTollm: false,
    additionalQuestion: true,
  },
});

export const projectSummarySuccess = (data) => ({
  type: SAVE_PROJECT_SUMMARY_DATA,
  payload: { ...data, summaryFromLlm: false },
});

export const projectSummaryBack = (data) => ({
  type: BACK_PROJECT_BASIC,
  payload: { ...data },
});

export const projectSummaryFileBack = (data) => ({
  type: BACK_PROJECT_SUMMARY,
  payload: { ...data },
});

export const projectQuestionBack = (data) => ({
  type: BACK_PROJECT_QUESTION,
  payload: { ...data },
});

export const projectFinalBack = (data) => ({
  type: BACK_PROJECT_FINAL,
  payload: { ...data },
});

export const projectDataSuccess = (data) => ({
  type: SAVE_PROJECT_DATA,
  payload: { ...data, additionalQuestion: false },
});

export const goToDashboard = (data) => ({
  type: SAVE_PROJECT_GO_DASHBOARD,
  payload: {
    ...data,
    openBackdrop: false,
    basicData: true,
    summaryData: false,
    fileSentTollm: false,
    additionalQuestion: false,
  },
});

export const contactUs = (data) => ({
  type: CONTACT_US_SUCCESS,
  payload: {
    ...data,
    basicData: false,
    contactUs: true,
    finalSteps: false,
    summaryData: false,
    fileSentTollm: false,
    additionalQuestion: false,
  },
});

export const projectDataSave = (data) => ({
  type: PROJECT_DATA_SAVE,
  payload: { projectData: data },
});

export const projectSummarySaveDraft = (data) => ({
  type: PROJECT_DATA_SAVE_DRAFT,
  payload: { ...data },
});

export const projectDataSuccessDraft = (data) => ({
  type: SAVE_PROJECT_DATA,
  payload: { ...data },
});

export const saveProject = (data) => ({
  type: INITIAL_PROJECT_DATA_SAVE,
  payload: { projectFetch: data[0], projectData: data[0] },
});



export const ADD_PROJECT_PENDING = 'ADD_PROJECT_PENDING';
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_FAILURE = 'ADD_PROJECT_FAILURE';

const addProjectRequest = () => ({
  type: ADD_PROJECT_PENDING,
});

const addProjectSuccess = (project) => {
  return ({
    type: ADD_PROJECT_SUCCESS,
    payload: project,
  })
};

const addProjectFailure = (error) => ({
  type: ADD_PROJECT_FAILURE,
  payload: error,
});


export const addProject = (raw) => async (dispatch) => {
  dispatch(addProjectRequest())
  try {
    
    const res = await API.addProject(raw);
  
    dispatch(addProjectSuccess(res))
    return res;
  } catch (err) {
    dispatch(addProjectFailure(err))
  }
}




export const highlightCompanyDetails = () =>({
  type: HIGHLIGHT_COMPANY_DETAILS,
  payload: { highlightCompanyDetails : true}
})

export const highlightCompanyDetailsNot = () =>({
  type: HIGHLIGHT_COMPANY_DETAILS,
  payload: { highlightCompanyDetails : false}
})



export const highlightFinancingRequirement = () =>({
  type: HIGHLIGHT_FINANCING_REQUIREMENT,
  payload: { highlightFinancingRequirement : true}
})

export const highlightFinancingRequirementNot = () =>({
  type: HIGHLIGHT_FINANCING_REQUIREMENT,
  payload: { highlightFinancingRequirement : false}
})


export const createProjectPopup = () =>({
  type: BACKDROP_OPEN_FULFILLED,
  payload: { createProjectpopup : true}
})

export const createProjectPopupNot = () =>({
  type: HIGHLIGHT_FINANCING_REQUIREMENT,
  payload: { createProjectpopup : false}
})