const config = {
    serverBaseUrl: '',
    baseURL: '',
    airtableUrl: 'https://api.airtable.com/v0/appeglvdKSlu26wb6/tblTc6UUVvC4QyWxB',
    airtableUrlDetails: 'https://api.airtable.com/v0/appeglvdKSlu26wb6/tblVfNpeul58yog0w',
    token: 'patOE003RnUBzCjgb.e85afe48e7695e6b06b7f273651d72435b9e6e78b6fe2d7ca957b3fee32bee7b',
    projectUrl: 'https://developer-api-vqv7qc6r6q-as.a.run.app/dashboard/',
    demoAccount: ['bdemo@refycap.com']
  };
 
  export default config;